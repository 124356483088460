import { errorHandler, notifyAction, successNotification } from "../../common/util/Helpers";
import * as server from "./authCrud";
import authSlice from "./authSlice";

// Auth Redux Actions
const {actions: {isLoading, setMode, setInvite, setNotification, authenticated, loggedOut }} = authSlice;


/*****
 *  Auth Handlers
 */


export const changeMode = mode => dispatch => {
        dispatch(setMode(mode));
}

export const trackInvite = data => dispatch => {
        dispatch(setInvite(data));
}
 
/**
* Invitation Acceptance
* @param {String} code 
*/
export const acceptInvite = code => dispatch => {
  server.acceptInviteReq(code)
         .then(_ => dispatch(setNotification(successNotification("Team Joined Successfully", "joinedTeam"))))
         .catch(errorHandler(dispatch, setNotification));
 };

/**
* User Email Verification
* @param {string} email
*/
export const requestEmailOTP = email => dispatch => {
  dispatch(isLoading());
  server.requestEmailOTPReq(email)
         .then(_ => dispatch(setNotification(successNotification("Check your email address for the OTP code", "emailOTPSent"))))
         .catch(errorHandler(dispatch, setNotification, "failedRequestEmailOTP"));
 };

 /**
 * User Email OTP Verification
 * @param {string} email
 */
 export const verifyEmail = data => dispatch => {
   dispatch(isLoading());
   server.verifyEmailReq(data)
          .then(_ => dispatch(setNotification(notifyAction("emailOTPVerified"))))
          .catch(errorHandler(dispatch, setNotification, "invalidOTP", null, false));
  };
 
 /**
 * User Registration
 * @param {Object} data email, password, firstName, lastName
 */
 export const register = data => dispatch => {
   dispatch(isLoading());
   server.registerReq(data)
          .then(_ => dispatch(setNotification(notifyAction("accountRegistered"))))
          .catch(errorHandler(dispatch, setNotification));
  };

 /**
 * Activate Account
 * @param {Object} data email, otp
 */
 export const activateAccount = data => dispatch => {
   dispatch(isLoading());
   server.activateAccountReq(data)
          .then(_ => dispatch(setNotification(notifyAction("accountActivated"))))
          .catch(errorHandler(dispatch, setNotification, "invalidOTP", null, false));
  };

/**
 * Resend Activation Code
 * @param {String} email
 */
export const resendActivationCode = email => dispatch => {
        dispatch(isLoading());
        server.resendActivationCodeReq(email)
                .then(_ => dispatch(setNotification(successNotification("Activation Code sent to your email address"))))
                .catch(errorHandler(dispatch, setNotification));
};

 /**
 * User Login
 * @param {Object} authData email, password
 */
 export const login = authData => dispatch => {
  dispatch(isLoading());
  server.loginReq(authData)
         .then(({ data: {data: {token:liveToken, ...profile}, testCredentials}}) => dispatch(authenticated({liveToken, testToken: testCredentials?.token, profile})))
         .catch(errorHandler(dispatch, setNotification, "allow401", null));
 };

/**
* User Forgot Password
* @param {String} email
*/
export const forgotPassword = email => dispatch => {
 dispatch(isLoading());
 server.forgotPasswordReq(email)
        .then(_ => dispatch(setNotification(notifyAction("recoveryOTPSent"))))
        .catch(errorHandler(dispatch, setNotification));
};

/**
* User Reset Password
* @param {Object} data token, newPassword, confirmNewPassword
*/
export const resetPassword = data => dispatch => {
 dispatch(isLoading());
 server.resetPasswordReq(data)
        .then(_ => dispatch(setNotification(successNotification("Password reset successful", "passwordReset"))))
        .catch(errorHandler(dispatch, setNotification));
};


/**
* User Log out
*/
export const logout = _ => dispatch => {
        dispatch(loggedOut());
};