import axios from "axios";

/*****
 *  Auth Request
 */
 
const BASE_PATH = "/user";

export const acceptInviteReq = code => {
  return axios.post(`/business/accept-invite`, {code});
}

export const requestEmailOTPReq = email => {
  return axios.post(`${BASE_PATH}/create/request-otp`, {email});
}
export const verifyEmailReq = data => {
  return axios.post(`${BASE_PATH}/create/verify-otp`, data);
}

export const registerReq = data => {
  return axios.post(`${BASE_PATH}/create`, data);
}

export const activateAccountReq = data => {
  return axios.post(`${BASE_PATH}/activate`, data);
}

export const resendActivationCodeReq = email => {
  return axios.post(`${BASE_PATH}/resend-code`, {email});
}

export const loginReq = authData => {
 return axios.post(`${BASE_PATH}/login`, authData, {passToken: false});
}

export const forgotPasswordReq = email => {
  return axios.post(`${BASE_PATH}/reset-password`, { email });
}

export const resetPasswordReq = data => {
  return axios.post(`${BASE_PATH}/confirm-reset`,  data);
}