import React from 'react';
import './scss/index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import LogRocket from 'logrocket';
import mixpanel from "mixpanel-browser";

const { PUBLIC_URL, REACT_APP_LOG_ROCKET_ID, NODE_ENV, REACT_APP_MIXPANEL_TOKEN } = process.env;

// initialize log rocket and Mixpanel
if (NODE_ENV === "production"){
      if (REACT_APP_LOG_ROCKET_ID){
          LogRocket.init(REACT_APP_LOG_ROCKET_ID);
      }
      if (REACT_APP_MIXPANEL_TOKEN){
        mixpanel.init(REACT_APP_MIXPANEL_TOKEN, {
            debug: false,
            track_pageview: "full-url",
            persistence: "localStorage",
          });
      }
}

const root = createRoot(document.getElementById('root'));
root.render(<BrowserRouter basename={PUBLIC_URL} >  
                <App />
            </BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
