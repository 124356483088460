import {createSlice} from "@reduxjs/toolkit";

const initialSettingsState = {
  isLoading: false,
  notification: null,
  profile: null,
  business: null,
  businesses: null,
  webhooks: null,
  members: null,
  invites: null
};

export default createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    // Settings Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Settings Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Settings Reducers
     */

    // changeActiveBusiness
    setBusiness: (state, action) => {
      state.business = action.payload || {};
    },

    // fetchProfile
    profileFetched: (state, action) => {
      state.isLoading = false; 
      state.profile = action.payload || {};
    },

    // fetchBusiness
    businessFetched: (state, action) => {
      state.isLoading = false; 
      const businesses = action.payload?.map(item => ({...item, status: item?.status?.replace("approved", "active")})) || [];
      state.business = businesses?.find(item => [localStorage.getItem("testBusinessId"), localStorage.getItem("liveBusinessId")].includes(`${item.id}`)) || businesses?.[0] || {};
      state.businesses = businesses;
    },

    // fetchWebhooks
    webhooksFetched: (state, action) => {
      state.webhooks = action.payload || [];
    },

    // fetchMembers
    membersFetched: (state, action) => {
      const {data, type} = action.payload
      state[type] = data?.filter(item => !item?.isAccepted);
    },

  }
});