import { useSelector } from "react-redux";

/**
 * Get Test Mode Status
 * @returns {boolean}
 */
const useIsTestMode = _ => {
    return useSelector(({auth}) => auth.mode === 'test');
}

export default useIsTestMode;