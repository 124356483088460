import {createSlice} from "@reduxjs/toolkit";
import { parseJWT } from "app/common/util/Helpers";

const initialAuthState = {
  isLoading: false,
  token: null,
  liveToken: null,
  liveTokenExp: null,
  testToken: null,
  testTokenExp: null,
  notification: null,
  mode: "live",
  invite: null
};

export default createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    // Auth Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Auth Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    // changeMode
    setMode: (state, action) => {
      state.token = action.payload === "test" ? state.testToken : state.liveToken;
      state.mode = action.payload;
    },

    // trackInvite
    setInvite: (state, action) => {
      state.invite = action.payload;
    },
    
    /*****
     *  Auth Reducers
     */


    // login
    // register
    authenticated: (state, action) => {
      state.isLoading = false; 
      state.refreshingToken = false;
      state.token = state.mode === "test" ? action.payload.testToken : action.payload.liveToken;
      state.liveToken = action.payload.liveToken;
      state.liveTokenExp = parseJWT(action.payload.liveToken)?.exp;
      state.testToken = action.payload.testToken;
      state.testTokenExp = parseJWT(action.payload.testToken)?.exp;
      state.notification = action.payload.notification;
    },

    // user Logout (See root reducer)
    loggedOut: _ => {}
  }
});