import { shallowEqual, useSelector } from "react-redux";

/**
 * Get Current Business Details
 * @returns {Object} business
 */
const useBusiness = _ => {
    const business = useSelector(({settings}) => settings.business, shallowEqual);
    return business;
}

export default useBusiness;